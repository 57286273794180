import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';

const Spinner: React.FC<
  { className?: string; size?: number } & Record<string, unknown>
> = ({ className, size = 40, ...props }) => (
  <CircularProgress
    className={className}
    color={'secondary'}
    size={size}
    {...props}
  />
);

export default Spinner;
