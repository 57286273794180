import { useLazyQuery } from '@apollo/client';
import {
  Favorite,
  ForkRight,
  MonitorHeart,
} from '@mui/icons-material';
import { Typography } from '@mui/material';
import { Box, Pagination, styled } from 'components';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import React, { useContext, useEffect, useState } from 'react';
import styles from 'styles/Retention/Organization/OrganizationLocations';
import { Avatar, Spinner } from 'components/shared';
import { LOCATION_STATS } from 'api';
import { RetentionContext } from '../../utils';
import {
  ILocationStats,
  ILocationStatsLazyQuery,
} from './types';

const StyledBox = styled(Box)(styles);
const OrgLocationRow = ({
  address,
  imageUrl,
  name,
  employeePulse,
  interactionsScore,
  skillsCompleted,
}: ILocationStats) => (
  <Box className={'stat-location-row'}>
    <Box className={'stat-location-company'}>
      <Avatar
        url={imageUrl}
        type={'COMPANY'}
        height={40}
        width={40}
      />
      <Box>
        <Typography variant={'h3'}>{name}</Typography>
        <Typography variant={'body1'}>{address}</Typography>
      </Box>
    </Box>
    <Box className={'location-stat-container'}>
      <Box>
        <Box className={'location-stat-title-row'}>
          <MonitorHeart className={'location-stat-icon'} />
          <Typography variant={'body1'}>
            Interaction score
          </Typography>
        </Box>
        <Typography
          variant={'h2'}
          className={'location-stat-value'}
        >
          {interactionsScore.value > 0 ? (
            <Typography
              variant={'caption'}
              className={
                interactionsScore.value > 50
                  ? 'status-good'
                  : 'status-bad'
              }
            >
              {interactionsScore.value}
            </Typography>
          ) : (
            <Typography
              className={'status-bad'}
              variant={'caption'}
            >
              0%
            </Typography>
          )}
        </Typography>
      </Box>
      <Box>
        <Box className={'location-stat-title-row'}>
          <ForkRight className={'location-stat-icon'} />
          <Typography variant={'body1'}>
            Skills Completed
          </Typography>
        </Box>
        <Typography
          variant={'h2'}
          className={'location-stat-value'}
        >
          <Typography
            variant={'caption'}
            className={
              skillsCompleted.value / skillsCompleted.total > 50
                ? 'status-good'
                : 'status-bad'
            }
          >
            {skillsCompleted.value}
          </Typography>
          <Typography variant={'caption'}>
            /{skillsCompleted.total}
          </Typography>
        </Typography>
      </Box>
      <Box>
        <Box className={'location-stat-title-row'}>
          <Favorite className={'location-stat-icon'} />
          <Typography variant={'body1'}>
            Employee Pulse
          </Typography>
        </Box>
        <Typography
          variant={'h2'}
          className={'location-stat-value'}
        >
          <Typography
            variant={'caption'}
            className={
              employeePulse.total / employeePulse.value >= 0.5
                ? 'status-good'
                : 'status-bad'
            }
          >
            {employeePulse.total > 0 && employeePulse.value > 0
              ? (
                  (employeePulse.total / employeePulse.value) *
                  100
                ).toFixed()
              : 0}
            %
          </Typography>
        </Typography>
      </Box>
    </Box>
  </Box>
);

const OrganizationLocations = () => {
  const { organizationId } = useContext(RetentionContext);
  const [pageCount, setPageCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);

  const [fetchLocationStats, { data, loading }] =
    useLazyQuery<ILocationStatsLazyQuery>(LOCATION_STATS, {
      fetchPolicy: 'cache-first',
    });

  useEffect(() => {
    fetchLocationStats({
      variables: {
        organizationId,
        offset: 0,
      },
    });
  }, []);

  useEffect(() => {
    setPageCount(data?.locationsStats?.pages);
  }, [data]);

  const handlePageChange = async (e, newPageNumber) => {
    await fetchLocationStats({
      variables: {
        organizationId,
        offset: newPageNumber - 1,
      },
    });
    setPageNumber(newPageNumber);
  };

  if (loading) {
    return (
      <StyledBox>
        <Spinner />
      </StyledBox>
    );
  }

  if (isEmpty(get(data, 'locationsStats.results', null))) {
    return (
      <StyledBox>
        <Typography variant={'h5'}>
          There are no locations associated to your account
        </Typography>
      </StyledBox>
    );
  }

  return (
    <StyledBox>
      {data.locationsStats.results.map((locationStats) => (
        <OrgLocationRow
          key={locationStats.imageUrl}
          {...locationStats}
        />
      ))}
      <Pagination
        count={pageCount}
        size={'medium'}
        onChange={handlePageChange}
        page={pageNumber}
      />
    </StyledBox>
  );
};

export default OrganizationLocations;
