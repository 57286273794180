import { gql } from '@apollo/client';

export const EMPLOYEE_RATING_TO_EMPLOYER_PROFILE = gql`
  mutation postEmployeeRatingToEmployerProfile($score: Int, $experienceId: Int) {
    postEmployeeRatingToEmployerProfile(score: $score, experienceId: $experienceId) {
      id
      score
      employeeProfileProfileId
      employerProfileProfileId
    }
  }
`;

export const CREATE_EMPLOYER_RATING_REASONS_TO_EMPLOYER_PROFILE = gql`
  mutation createEmployerRatingReasonsToEmployerProfile($experienceId: Int, $reasonIds: [Int]) {
    createEmployerRatingReasonsToEmployerProfile(
      experienceId: $experienceId
      reasonIds: $reasonIds
    ) {
      id
      reasonId
      employeeProfileProfileId
      employerProfileProfileId
    }
  }
`;

export const ASSOCIATE_EMPLOYEE_TO_EMPLOYER = gql`
  mutation createEmployeeProfileToEmployerCode($code: String, $experienceId: Int) {
    createEmployeeProfileToEmployerCode(code: $code, experienceId: $experienceId) {
      id
      employerCodeId
      employeeProfileProfileId
    }
  }
`;
