import ReactPixel from 'react-facebook-pixel';

export const fbPixel = () => {
  const options = {
    autoConfig: true, // set pixel's autoConfig.
    debug: false, // enable logs
  };
  ReactPixel.init(process.env.FB_PIXEL_ID, options);
  ReactPixel.pageView();
}

export const trackPixelEvent = (event) => {
  ReactPixel.track(event);
}