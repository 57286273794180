import { gql } from '@apollo/client';

export const SET_COMPANY_ADDRESS = gql`
  mutation setEmployerAddress(
    $address: String!
    $stateId: Int!
    $countryId: Int!
    $city: String!
    $zip: String!
  ) {
    setEmployerAddress(
      address: $address
      stateId: $stateId
      countryId: $countryId
      city: $city
      zip: $zip
    ) {
      profile_id
      user_id
      address
      city
      countryId
      stateId
      zip
    }
  }
`;

export const SET_COMPANY_NAME = gql`
  mutation setEmployerName($name: String!) {
    setEmployerName(name: $name) {
      profile_id
      user_id
      name
    }
  }
`;

export const SET_COMPANY_SIZE = gql`
  mutation setEmployerSize($size: String!) {
    setEmployerSize(size: $size) {
      profile_id
      user_id
      size
    }
  }
`;

export const SET_EMPLOYER_PHONE = gql`
  mutation setEmployerPhone($phone: String!) {
    setEmployerPhone(phone: $phone) {
      profile_id
      user_id
      phone
    }
  }
`;
