import { gql } from '@apollo/client';

export const UPDATE_EMPLOYEE_PROFILE_IMG = gql`
  mutation postEmployeeImage($file: Upload!) {
    postEmployeeImage(file: $file) {
      filename
      mimetype
      encoding
    }
  }
`;

export const UPDATE_EMPLOYEE_PROFILE_VIDEO = gql`
  mutation postEmployeeVideo($file: Upload!) {
    postEmployeeVideo(file: $file) {
      filename
      mimetype
      encoding
    }
  }
`;

export const UPDATE_EMPLOYEE_PROFILE_CV = gql`
  mutation postEmployeeResumeFile($file: Upload!) {
    postEmployeeResumeFile(file: $file) {
      filename
      mimetype
      encoding
    }
  }
`;

export const GET_EMPLOYEE_PROFILE_INFO = gql`
  mutation downloadEmployeeProfileInfo($user_id: Int) {
    downloadEmployeeProfileInfo(user_id: $user_id) {
      analytic
    }
  }
`;

export const UPDATE_USER_PROFILE = gql`
  mutation postEmployeeUpdate(
    $motto: String
    $gender: String
    $race: String
    $phone: String
    $address: String
    $name: String
    $stateId: Int
    $countryId: Int
    $city: String
    $zip: String
  ) {
    postEmployeeUpdate(
      motto: $motto
      gender: $gender
      race: $race
      phone: $phone
      address: $address
      name: $name
      stateId: $stateId
      countryId: $countryId
      city: $city
      zip: $zip
    ) {
      address
      birth_date
      createdAt
      countryId
      city
      gender
      imageUrl
      introVideoUrl
      motto
      name
      race
      phone
      profile_id
      profileWizard
      profileComplete
      resumeUrl
      stateId
      updatedAt
      user_id
      website
      zip
    }
  }
`;

export const ADD_CERTIFICATE = gql`
  mutation addCertificate(
    $name: String
    $completionDate: String
    $complete: Boolean
    $employeeProfileId: Int
    $validatedByEmployerProfileId: Int
  ) {
    addCertificate(
      name: $name
      completionDate: $completionDate
      complete: $complete
      employeeProfileId: $employeeProfileId
      validatedByEmployerProfileId: $validatedByEmployerProfileId
    ) {
      id
      name
      complete
      employeeProfileId
      validatedByEmployerProfileId
      completionDate
    }
  }
`;

export const ADD_CERTIFICATES = gql`
  mutation addCertificates($certificates: [CertificatesInputType], $employeeProfileId: Int) {
    addCertificates(certificates: $certificates, employeeProfileId: $employeeProfileId) {
      completionDate
      complete
      employeeProfileId
      id
      name
      validatedByEmployerProfileId
    }
  }
`;

export const UPDATE_CERTIFICATES = gql`
  mutation postCertificates($certificates: [CertificatesInputType], $employeeProfileId: Int) {
    postCertificates(certificates: $certificates, employeeProfileId: $employeeProfileId) {
      completionDate
      complete
      employeeProfileId
      id
      name
      validatedByEmployerProfileId
    }
  }
`;

export const UPDATE_USER_EDUCATION = gql`
  mutation postEmployeeEducation($education: [EducationInputType]) {
    postEmployeeEducation(education: $education) {
      education {
        currentJob
        education_id
        endDate
        name
        pos
        startDate
      }
    }
  }
`;

export const UPDATE_USER_EXP = gql`
  mutation postEmployeeExperience($experience: [ExperienceInputType]) {
    postEmployeeExperience(experience: $experience) {
      experience {
        currentJob
        endDate
        experience_id
        name
        pos
        startDate
      }
    }
  }
`;

export const UPDATE_USER_INDUSTRY = gql`
  mutation postEmployeeIndustry($industry: [IndustryInputType]) {
    postEmployeeIndustry(industry: $industry) {
      user_id
      industry {
        name
        industry_id
      }
    }
  }
`;

export const UPDATE_USER_SKILLS = gql`
  mutation postEmployeeSkills($skills: [SkillsInputType]) {
    postEmployeeSkills(skills: $skills) {
      user_id
    }
  }
`;

export const UPDATE_USER_RESUME = gql`
  mutation postEmployeeResume($website: String!) {
    postEmployeeResume(website: $website) {
      user_id
      profile_id
      website
    }
  }
`;

export const UPDATE_USER_NOTIFICATIONS = gql`
  mutation postEmployeeUpdateNotification(
    $inappNewMessages: Boolean
    $emailNewMessages: Boolean
    $inappNewJobs: Boolean
    $emailNewJobs: Boolean
    $inappTips: Boolean
    $emailTips: Boolean
  ) {
    postEmployeeUpdateNotification(
      inappNewMessages: $inappNewMessages
      emailNewMessages: $emailNewMessages
      inappNewJobs: $inappNewJobs
      emailNewJobs: $emailNewJobs
      inappTips: $inappTips
      emailTips: $emailTips
    ) {
      inappNewMessages
      emailNewMessages
      inappNewJobs
      emailNewJobs
      inappTips
      emailTips
    }
  }
`;

export const UPDATE_USER_PASSWORD = gql`
  mutation postEmployeeNewPassword($userId: Int!, $pass: String!, $oldPass: String!) {
    postEmployeeNewPassword(user_id: $userId, pass: $pass, oldPass: $oldPass) {
      user_id
      pass
    }
  }
`;
