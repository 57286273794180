import Typography from '@mui/material/Typography';
import { Box, styled } from '../../../../components';
import {
  Button,
  Input,
  Spinner,
} from '../../../../components/shared';
import React, {
  SyntheticEvent,
  useContext,
  useEffect,
  useState,
} from 'react';
import { RetentionContext } from '../../../../components/Retention/utils';
import { useLocation, useNavigate } from 'react-router-dom';
import { getRoutes } from '../../../../utils';
import useBadgesQuery from '../../../../hooks/operations/useBadgesQuery';
import { useMutation } from '@apollo/client';
import { CREATE_NEW_SKILL } from '../../../../api';
import { get } from 'lodash';

const StyledBox = styled(Box)(({ theme }) => ({
  '& .create-path-title-container': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  '& .create-path-action-container': {
    display: 'flex',
    alignItems: 'center',
    button: { marginLeft: 10 },
  },
  '& .badge-container': {
    display: 'flex',
    flexWrap: 'wrap',
    maxHeight: 155,
    overflowY: 'scroll',
    '& > div': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      img: {
        maxWidth: 60,
      },
    },
  },
  '& .badgeUrlContainer': {
    border: `1px solid transparent`,
    borderRadius: 4,
    '&.active': {
      borderColor: theme.palette.secondary.light,
    },
  },
}));

type ActiveBadge = {
  key: string;
  url: string;
};
const CreateNewSkill = () => {
  const ctx = useContext(RetentionContext);
  const navigate = useNavigate();
  const location = useLocation();
  const routes = getRoutes();
  const [pathItemTitle, setPathItemTitle] = useState<string>('');
  const [pathItemDescription, setPathItemDescription] =
    useState<string>('');
  const [activeBadge, setActiveBadge] =
    useState<ActiveBadge | null>(null);
  const [formError, setFormError] = useState<string | null>(
    null,
  );

  const { fetchBadgeImages, badgeImages, badgeImagesLoading } =
    useBadgesQuery({
      skillBadgeVars: { limit: 200 },
      abilityBadgeVars: { limit: 200 },
    });

  useEffect(() => {
    fetchBadgeImages();
  }, []);

  const handleSetPathItemTitle = (e: SyntheticEvent) => {
    setPathItemTitle(e.target.value);
  };

  const handleSetPathItemDescription = (e: SyntheticEvent) => {
    setPathItemDescription(e.target.value);
  };

  const [createNewSkill, { loading }] = useMutation(
    CREATE_NEW_SKILL,
    {
      fetchPolicy: 'no-cache',
    },
  );

  const createNewPath = async () => {
    if (!pathItemTitle)
      setFormError('A skill title is required');
    if (!pathItemDescription)
      setFormError('Skill description is required');
    if (!activeBadge) setFormError('A badge image is required');
    if (!pathItemTitle || !pathItemDescription || !activeBadge)
      return;
    const newSkill = await createNewSkill({
      variables: {
        name: pathItemTitle,
        description: pathItemDescription,
        badgeUrl: activeBadge.key,
      },
    });
    if (newSkill) {
      navigate(
        `${routes.employer.retention_portal.location}/${ctx.retentionPages.pathmanager}`,
      );
    }
  };

  const handleAddBadge = (badgeKey: ActiveBadge) => {
    setActiveBadge(badgeKey);
  };

  return (
    <StyledBox>
      {badgeImagesLoading ? (
        <Box>
          <Spinner />
        </Box>
      ) : (
        <Box>
          <Box className={'create-path-title-container'}>
            <Typography variant={'h2'}>
              Create custom skill
              {get(location, 'state.pathItem', null) && (
                <>
                  <br />
                  <Typography
                    variant={'body2'}
                    color={'success'}
                  >
                    Create a skill for your new path item
                  </Typography>
                </>
              )}
            </Typography>
            <Box className={'create-path-action-container'}>
              <Button
                onClick={() =>
                  navigate(
                    `${routes.employer.retention_portal.location}/${ctx.retentionPages.pathmanager}`,
                  )
                }
                variant={'outlined-secondary'}
                disabled={loading}
              >
                Back
              </Button>
              <Button
                onClick={createNewPath}
                variant={'filled-primary'}
                disabled={loading}
              >
                Save
              </Button>
            </Box>
          </Box>
          {formError && (
            <Box>
              <Typography variant={'body1'} color={'error'}>
                {formError}
              </Typography>
            </Box>
          )}
          <Box>
            <Box sx={{ mt: 2 }}>
              <Typography variant={'h3'}>Name</Typography>
              <Input
                disabled={loading}
                value={pathItemTitle}
                onChange={handleSetPathItemTitle}
              />
            </Box>
            <Box sx={{ mt: 2 }}>
              <Typography variant={'h3'}>Description</Typography>
              <Typography variant={'body1'}>
                Create a description that your users will use to
                help guide them to complete this goal.
              </Typography>
              <Input
                disabled={loading}
                value={pathItemDescription}
                onChange={handleSetPathItemDescription}
                multiline
                inputProps={{ rowsmax: 5 }}
                inputClassName="textarea"
                htmlInputClassName="textareaInput"
                labelClassName="label"
              />
            </Box>
            <Box sx={{ mt: 2 }}>
              <Typography>
                Choose a badge to denote the skill you're
                creating in your path.
              </Typography>
            </Box>
            <Box>
              <Box className={'badge-container'}>
                {(badgeImages || []).map(
                  (badge: { key: string; url: string }) => (
                    <div
                      onClick={() => handleAddBadge(badge)}
                      className={[
                        'badgeUrlContainer',
                        get(activeBadge, 'key', null) ===
                        badge.key
                          ? 'active'
                          : '',
                      ].join(' ')}
                    >
                      <img src={badge.url} alt="" />
                    </div>
                  ),
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </StyledBox>
  );
};

export default CreateNewSkill;
