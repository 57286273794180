import React from 'react';

const CheckMark = ({fill}) => (
  <svg fill={fill} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8.93 7.96">
    <path className="cls-1"
          d="M186.1,310.08l.21-.32a8.43,8.43,0,0,1,1-1.42c.29-.31.58-.63.88-.92a9.81,9.81,0,0,1,2.09-1.52l.83-.41a1.07,1.07,0,0,1,.6-.12.72.72,0,0,1,.36.14c.26.24.3.48,0,.71l-.61.44a13.64,13.64,0,0,0-1.54,1.5,14.59,14.59,0,0,0-1.19,1.53c-.33.49-.66,1-1,1.5s-.48.87-.71,1.3a1.51,1.51,0,0,0-.09.18,1,1,0,0,1-.82.64,1,1,0,0,1-1.13-.5c-.19-.33-.34-.68-.5-1-.24-.52-.47-1-.7-1.56-.14-.31-.29-.61-.41-.93a.69.69,0,0,1,.1-.75.75.75,0,0,1,1-.05l1,1.06Z"
          transform="translate(-183.38 -305.37)" />
  </svg>
);

export default CheckMark;
