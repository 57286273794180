export function getUserId() {
  return localStorage.getItem('userId');
}

export function getProfileId() {
  return localStorage.getItem('profileId');
}

export function getAuthToken() {
  return localStorage.getItem('token');
}

export function removeLocalStorageItems(items = []) {
  items.map((item) => localStorage.removeItem(item));
}

export function saveProfileId(profileId) {
  if (profileId) localStorage.setItem('profileId', profileId);
  else throw new Error('Failed saving profileId to localStorage');
}

export function saveUserId(userId) {
  if (userId) localStorage.setItem('userId', userId);
  else throw new Error('Failed saving userId to localStorage');
}

export function saveRequiredUserValues(profile = {}) {
  saveUserId(profile.user_id);
  saveProfileId(profile.profile_id);
}
