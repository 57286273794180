// Log every time state is changed
const { NODE_ENV } = process.env;

export const log = (config) => (set, get, api) =>
  NODE_ENV === 'development'
    ? config(
        (...args) => {
          // eslint-disable-next-line no-console
          console.log('%c🐻 applying', 'color:#7ed75e;font-weight:bold;', args);
          set(...args);
          // eslint-disable-next-line no-console
          console.log('%c🐻 new state', 'color:#8ab1fb;font-weight:bold;', get());
        },
        get,
        api
      )
    : config(set, get, api);
