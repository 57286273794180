import { gql } from '@apollo/client';
import {
  JOB_CORE_FIELDS_FRAGMENT,
  JOB_QUESTIONS_FRAGMENT,
  USER_TO_JOBS_FRAGMENT
} from '../fragments';

export const GET_EMPLOYEE_JOBS = gql`
  query employeeJobs(
    $offset: Int
    $limit: Int
    $employerProfileId: Int
    $skills: String
    $industry: String
    $hireDate: String
    $payMin: Int
    $payMax: Int
    $payPeriodId: Int
    $currencyId: Int
  ) {
    employeeJobs(
      offset: $offset
      limit: $limit
      employerProfileId: $employerProfileId
      skills: $skills
      industry: $industry
      hireDate: $hireDate
      payMin: $payMin
      payMax: $payMax
      payPeriodId: $payPeriodId
      currencyId: $currencyId
    ) {
      total
      ...JobCoreFieldsFragment
      ...JobQuestionsFragment
    }
  }
  ${JOB_CORE_FIELDS_FRAGMENT}
  ${JOB_QUESTIONS_FRAGMENT}
`;

export const GET_MY_JOBS = gql`
  query myJobs($query: String) {
    myJobs(query: $query) {
      ...UserToJobsFragment
      jobs {
        ...JobCoreFieldsFragment
        ...JobQuestionsFragment
      }
    }
  }
  ${JOB_CORE_FIELDS_FRAGMENT}
  ${JOB_QUESTIONS_FRAGMENT}
  ${USER_TO_JOBS_FRAGMENT}
`;

export const AUTOCOMPLETE_ALL_JOBS_SEARCH = gql`
  query allJobsSearch($query: String, $employerProfileId: Int) {
    allJobsSearch(query: $query, employerProfileId: $employerProfileId) {
      total
      ...JobCoreFieldsFragment
      ...JobQuestionsFragment
    }
  }
  ${JOB_CORE_FIELDS_FRAGMENT}
  ${JOB_QUESTIONS_FRAGMENT}
`;

export const AUTOCOMPLETE_MY_JOBS_SEARCH = gql`
  query myJobsSearch($query: String) {
    myJobsSearch(query: $query) {
      ...UserToJobsFragment
      jobs {
        ...JobCoreFieldsFragment
        ...JobQuestionsFragment
      }
    }
  }
  ${JOB_CORE_FIELDS_FRAGMENT}
  ${JOB_QUESTIONS_FRAGMENT}
  ${USER_TO_JOBS_FRAGMENT}
`;
