import React, { useState } from 'react';
import { Typography } from '@mui/material';
import { Box, styled } from 'components';
import { Button } from 'components/shared';
import {
  REQUEST_ABILITY_COMPLETION,
  REQUEST_SKILL_COMPLETION,
} from 'api';
import { useMutation } from '@apollo/client';
import { Controller, useForm } from 'react-hook-form';
import { get } from 'lodash';
import badgeCardStyles from '../../../styles/Badges/badgeCard';
import { MdAttachFile, MdCheck } from '../../icons';

type StyledCardProps = {
  isComplete: boolean;
} & React.HTMLAttributes<HTMLDivElement>;

const StyledCard =
  styled('div')<StyledCardProps>(badgeCardStyles);

interface Badge {
  abilitiesBadgeId: number;
  abilitiesId: number;
  skillsId: number;
  badgeUrl: string;
  name: string;
  description: string;
  status: string;
}

const UploadLabel = styled('label')({
  width: '100%',
  marginBottom: 10,
  display: 'flex',
  justifyContent: 'center',
});

type GrowBadgeCardFormProps = {
  skillId?: number;
  abilityId?: number;
  file?: string | ArrayBuffer | null;
};

const GrowBadgeCard = ({ badge }: { badge: Badge }) => {
  const [buttonClass, setButtonClass] = useState(badge.status);

  const isComplete = buttonClass !== 'initial';

  const getDefaultValues = () => {
    const defaultValues = {};
    if (badge.skillsId) {
      defaultValues.skillId = badge.skillsId;
    }
    if (badge.abilitiesId) {
      defaultValues.abilityId = badge.abilitiesId;
    }
    return defaultValues;
  };

  const { control, getValues, watch } =
    useForm<GrowBadgeCardFormProps>({
      defaultValues: getDefaultValues(),
    });

  const fileExists = watch('file');

  const [
    requestSkillApproval,
    { error: requestSkillCompletionError },
  ] = useMutation(REQUEST_SKILL_COMPLETION, {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  });

  const [
    requestAbilityApproval,
    { error: requestAbilityCompletionError },
  ] = useMutation(REQUEST_ABILITY_COMPLETION, {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  });

  const badgeDescription = () => {
    if (badge.description) {
      return badge.description.length > 75
        ? `${badge.description.slice(0, 74)}...`
        : badge.description;
    }
    return 'Description Not Available';
  };

  const getButtonText = () => {
    if (badge.status === 'initial') return 'Request approval';
    if (buttonClass === 'requested') return buttonClass;
    return badge.status;
  };

  const buttonId = badge.abilitiesId
    ? `upload-${badge.abilitiesId}`
    : `upload-${badge.skillsId}`;

  const handleSubmitForm = async () => {
    const formValues: GrowBadgeCardFormProps = getValues();

    if (formValues.abilityId) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { skillId, ...variables } = formValues;
      const resp = await requestAbilityApproval({
        variables,
      });
      const wasSuccessful = get(
        resp,
        'data.requestAbilityCompletion.success',
        false,
      );
      setButtonClass(wasSuccessful ? 'Requested' : badge.status);
    } else if (formValues.skillId) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { abilityId, ...variables } = formValues;
      const resp = await requestSkillApproval({
        variables,
      });
      const wasSuccessful = get(
        resp,
        'data.requestSkillCompletion.success',
        false,
      );
      setButtonClass(wasSuccessful ? 'Requested' : badge.status);
    }

    return null;
  };

  return (
    <StyledCard completed={isComplete}>
      <form>
        <Box className="badgeTitleWrapper">
          <img alt="badge" src={badge.badgeUrl} />
          <Box className="positioningWrapper">
            <Typography variant="h2">{badge.name}</Typography>
          </Box>
          <Typography variant={'body1'} fontSize={12} mt={1}>
            {badgeDescription()}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          mt={2}
        >
          <Controller
            name={'file'}
            control={control}
            render={({ field }) => (
              <Box position={'relative'}>
                <input
                  type="file"
                  id={buttonId}
                  accept={
                    'image/jpeg, image/png, image/tiff, video/*, audio/*, text/*, application/pdf, application/json'
                  }
                  disabled={isComplete}
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    zIndex: 1,
                    opacity: 0,
                    cursor: 'pointer',
                  }}
                  onChange={(e) => {
                    if (e.target.files && e.target.files[0]) {
                      const reader = new FileReader();
                      reader.readAsDataURL(e.target.files[0]);
                      reader.onloadend = () => {
                        field.onChange(e.target.files[0]);
                      };
                    }
                  }}
                />
                <UploadLabel htmlFor={buttonId}>
                  <Button
                    startIcon={
                      fileExists != null ? (
                        <MdCheck />
                      ) : (
                        <MdAttachFile />
                      )
                    }
                    component={'label'}
                    variant={'filled-primary'}
                    disabled={isComplete}
                    className={buttonClass}
                  >
                    Upload Docs
                  </Button>
                </UploadLabel>
              </Box>
            )}
          />
        </Box>
        <Button
          className={buttonClass}
          onClick={handleSubmitForm}
        >
          {getButtonText()}
        </Button>
        {requestAbilityCompletionError ||
        requestSkillCompletionError ? (
          <Typography className="errorText">
            {requestSkillCompletionError.graphQLErrors?.[0]
              ?.message ||
              requestAbilityCompletionError.graphQLErrors?.[0]
                ?.message}
          </Typography>
        ) : null}
      </form>
    </StyledCard>
  );
};
export default GrowBadgeCard;
