import { gql } from '@apollo/client';
import {
  JOB_PUBLIC_FIELDS_FRAGMENT,
  JOB_CORE_FIELDS_FRAGMENT,
  JOB_QUESTIONS_FRAGMENT
} from '../fragments';

export const GET_JOB = gql`
  query job($jobId: Int!) {
    job(jobId: $jobId) {
      approvedJob
      passedJob
      starredJob
      ...JobCoreFieldsFragment
    }
  }
  ${JOB_CORE_FIELDS_FRAGMENT}
`;

export const GET_PUBLIC_JOB = gql`
  query publicJob($jobId: Int!) {
    publicJob(jobId: $jobId) {
      ...JobPublicFieldsFragment
    }
  }
  ${JOB_PUBLIC_FIELDS_FRAGMENT}
`;

export const GET_QUESTIONS = gql`
  query questions($jobsJobsId: Int) {
    questions(jobsJobsId: $jobsJobsId) {
      id
      ...JobQuestionsFragment
    }
  }
  ${JOB_QUESTIONS_FRAGMENT}
`;

export const CLOSE_JOB_TO_JOB_BY_JOB_ID = gql`
  query closeJobToJobByJobId($jobId: Int) {
    closeJobToJobByJobId(jobId: $jobId) {
      id
      jobId
      closeJob {
        rating
        feedback
        hiredEmployeeProfileId
        closeReasonId
        hiringPlatformId
      }
    }
  }
`;

export const CLOSE_REASONS = gql`
  query closeReasons {
    closeReasons {
      id
      name
    }
  }
`;

export const HIRING_PLATFORMS = gql`
  query hiringPlatforms {
    hiringPlatforms {
      id
      name
    }
  }
`;
