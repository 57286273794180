import React from 'react';
import { MuiButtonBase } from 'components';

interface IButtonBase {
  analyticParams: {
    key: string;
    trigger?: string;
  };
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  children?: JSX.Element | string;
}

const ButtonBase = ({
  onClick = () => {},
  ...rest
}: IButtonBase) => {
  const handleClick = (
    e: React.MouseEvent<HTMLButtonElement>,
  ) => {
    if (onClick) onClick(e);
  };

  return <MuiButtonBase onClick={handleClick} {...rest} />;
};

export default ButtonBase;
