import React from 'react';
import {
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import Typography from '@mui/material/Typography';
import { Box, styled } from '../../../../material-ui';
import { Button } from '../../../../shared';
import { MdAdd } from '../../../../icons';
import SearchableDialog from '../../../../dialogs/SearchableDialog';
import useJobTypesQuery from '../../../../../hooks/operations/useJobTypesQuery';
import {
  IPathLevel,
  IPathLevelJobType,
  IPathLevelSkillsAbilities,
} from './types';
import { PathLevel } from './PathLevel';

const StyledBox = styled(Box)(() => ({
  '& .path-level-skills-action-row': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 15,
  },
}));

const NewPathLevel = ({
  count,
  abilitiesBadges,
  skillsBadges,
  handlePathLevelUpdate,
  handleDeletePathLevel,
  pathLevel,
}: {
  count: number;
  abilitiesBadges;
  skillsBadges;
  handlePathLevelUpdate?: (
    // eslint-disable-next-line no-unused-vars
    pathData: IPathLevelJobType | IPathLevelSkillsAbilities,
  ) => Dispatch<SetStateAction<Record<string, never>>>;
  handleDeletePathLevel?;
  pathLevel?: IPathLevel;
}) => {
  const [choosePathJobTypeIsOpen, setChoosePathJobTypeIsOpen] =
    useState(false);
  const [chooseSkillsIsOpen, setChooseSkillsIsOpen] =
    useState(false);
  const [error, setError] = useState<string | null>(null);

  const { getJobTypes, jobTypes, jobTypesLoading } =
    useJobTypesQuery();

  useEffect(() => {
    getJobTypes({ variables: { includeOrgJobTypes: true } });
  }, []);

  const renderDialogRow = (row) => (
    <Box>
      <Typography variant={'h2'}>{row.name}</Typography>
      <img
        alt={`badge of ${row.name}`}
        style={{ height: 25, width: 25 }}
        src={row.badgeUrl}
      />
    </Box>
  );

  const handleDeletePathLevelSkill = (id) => {
    const pathLevelSkillsAbilities = pathLevel.skillsAbilities;
    const indexToRemove = pathLevelSkillsAbilities.findIndex(
      (obj) => obj.id === id,
    );
    pathLevelSkillsAbilities.splice(indexToRemove, 1);
    handlePathLevelUpdate({
      skillsAbilities: pathLevelSkillsAbilities,
    });
  };

  const handleAddSkills = () => {
    if (pathLevel?.jobTypeId == null) {
      setError('Choose a path level before choosing skills');
      return;
    }
    setError(null);
    setChooseSkillsIsOpen(true);
  };

  return (
    <StyledBox>
      <PathLevel
        setChoosePathJobTypeIsOpen={setChoosePathJobTypeIsOpen}
        pathGroupItem={pathLevel}
        count={count}
        handleDeletePathGroupItem={handleDeletePathLevel}
        handleDeleteSkill={handleDeletePathLevelSkill}
        canEdit
        isActive
        handleOpen={() => null}
      />
      <Box className={'path-level-skills-action-row'}>
        <Button
          variant={'outlined-primary'}
          onClick={handleAddSkills}
        >
          <MdAdd />
          <Typography>Add Skills</Typography>
        </Button>
        {error && (
          <Typography variant={'body1'} color={'error'}>
            {error}
          </Typography>
        )}
      </Box>
      <SearchableDialog
        title={`Add skill/ability to level ${count}`}
        rows={[].concat.apply(
          [],
          [abilitiesBadges, skillsBadges],
        )}
        dataIsLoading={
          abilitiesBadges == null || skillsBadges == null
        }
        onClose={(badges) => {
          handlePathLevelUpdate({ skillsAbilities: badges });
          setChooseSkillsIsOpen(false);
        }}
        isOpen={chooseSkillsIsOpen}
        renderRow={renderDialogRow}
        multiSelect
        multiSelectLimit={10}
      />
      <SearchableDialog
        title={`Select Level ${count} Position`}
        rows={jobTypes}
        dataIsLoading={jobTypesLoading}
        onClose={(row) => {
          if (row) {
            handlePathLevelUpdate({
              jobTypeId: row.id,
              name: row.name,
            });
          }
          setChoosePathJobTypeIsOpen(false);
        }}
        isOpen={choosePathJobTypeIsOpen}
      />
    </StyledBox>
  );
};
export default NewPathLevel;
