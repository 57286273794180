import { gql } from '@apollo/client';

export const SAVE_INITIAL_CAREER_DEV = gql`
  mutation postCareerDevelopment(
    $curJobTypeId: Int
    $futureJobTypeId: Int
    $industryId: Int
    $currentlySearching: Boolean
    $showEmployerData: Boolean
  ) {
    postCareerDevelopment(
      curJobTypeId: $curJobTypeId
      futureJobTypeId: $futureJobTypeId
      industryId: $industryId
      currentlySearching: $currentlySearching
      showEmployerData: $showEmployerData
    ) {
      id
    }
  }
`;

// for onboarding only
export const CREATE_CAREER_DEV_ON_REGISTRATION = gql`
  mutation createCareerDev(
    $userId: Int
    $industryId: Int
    $curJobTypeId: Int
    $futureJobTypeId: Int
    $confirm: Boolean
  ) {
    createCareerDev(
      userId: $userId
      industryId: $industryId
      curJobTypeId: $curJobTypeId
      futureJobTypeId: $futureJobTypeId
      confirm: $confirm
    ) {
      id
    }
  }
`;

export const CREATE_CAREER_PATH = gql`
  mutation createCareerPathByEmployee(
    $startJobTypeId: Int!
    $endJobTypeId: Int!
    $profile_id: Int!
  ) {
    createCareerPathByEmployee(
      startJobTypeId: $startJobTypeId
      endJobTypeId: $endJobTypeId
      profile_id: $profile_id
    ) {
      careerPathGroupItems {
        id
        index
        jobTypeId
        completed
        active
        careerPathGroupId
        careerPathGroups {
          id
          startJobTypeId
          endJobTypeId
        }
      }
      abilitiesBadges
      skillsBadges
    }
  }
`;

export const POST_EMPLOYEE_STARRED = gql`
  mutation postEmployeeStarred(
    $careerDevelopmentId: Int
    $employeeProfileId: Int
  ) {
    postEmployeeStarred(
      careerDevelopmentId: $careerDevelopmentId
      employeeProfileId: $employeeProfileId
    ) {
      careerDevelopmentId
      id
    }
  }
`;

export const REMOVE_EMPLOYEE_STARRED = gql`
  mutation removeEmployeeStarred(
    $careerDevelopmentId: Int
    $employeeProfileId: Int
  ) {
    removeEmployeeStarred(
      careerDevelopmentId: $careerDevelopmentId
      employeeProfileId: $employeeProfileId
    ) {
      careerDevelopmentId
      id
    }
  }
`;

export const GET_EMPLOYEE_STARRED = gql`
  mutation getEmployeeStarred($careerDevelopmentId: Int) {
    getEmployeeStarred(
      careerDevelopmentId: $careerDevelopmentId
    ) {
      id
      employeeProfileId
      employeeProfile {
        profile_id
        user_id
        imageUrl
        name
        experience {
          name
          pos
          currentJob
        }
      }
    }
  }
`;

export const REQUEST_ABILITY_COMPLETION = gql`
  mutation RequestAbilityCompletion(
    $abilityId: Int!
    $file: Upload
  ) {
    requestAbilityCompletion(
      abilityId: $abilityId
      file: $file
    ) {
      success
      message
    }
  }
`;

export const REQUEST_SKILL_COMPLETION = gql`
  mutation RequestSkillCompletion(
    $skillId: Int!
    $file: Upload
  ) {
    requestSkillCompletion(skillId: $skillId, file: $file) {
      success
      message
    }
  }
`;
