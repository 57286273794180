import React from 'react';

const Filter = ({fill}) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.71 12.02" fill={fill}>
    <rect className="cls-1" width="19.71" height="0.88" />
    <rect className="cls-1" x="2.46" y="5.69" width="14.78" height="0.76" />
    <rect className="cls-1" x="4.76" y="11.39" width="10.19" height="0.63" />
  </svg>
);

export default Filter;
