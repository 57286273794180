import filter from 'lodash/filter';

const getCandidateActionName = (webUrl) => ({
  reject: { title: webUrl ? 'Remove' : 'Reject' },
  accept: { title: webUrl ? 'Accept' : 'Hire' },
  star: { title: webUrl ? 'Star' : 'Save' }
});

const getApplicantUserId = (data = {}) =>
  // quickHireMatches -> o.user_id, else -> o.users.user_id
  data?.user_id || data?.users?.user_id;

const getApplicantProfile = (data = {}) =>
  // quickHireMatches -> o.user_id, else -> o.users.user_id
  data?.employeeProfile || data?.users?.employeeProfile;

const filterActiveApplicants = (users = [], profileValPath = 'users.employeeProfile') =>
  // for quickmatch profileValPath = 'employeeProfile'
  filter(users, profileValPath);

export { getCandidateActionName, getApplicantUserId, getApplicantProfile, filterActiveApplicants };
