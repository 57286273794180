import reduce from 'lodash/reduce';
import { GET_REGISTRATION_INFO } from '../queries';
import { client } from '../graphql';

const DEFAULT_OBJ = {
  randomPerson: '',
  numberOfJobsInZip: 0,
  numberOfHires: 0
};

export default async function getOnboardingStatistics(zip = '', clientParam) {
  const clientToUse = clientParam || client;

  try {
    const res = await clientToUse.query({
      query: GET_REGISTRATION_INFO,
      variables: {
        zip
      },
      fetchPolicy: 'no-cache',
      errorPolicy: 'all'
    });
    if (res?.data?.getInfoForRegistration?.analytic) {
      const parsed = JSON.parse(res.data.getInfoForRegistration.analytic);
      return reduce(parsed, (result, val) => ({ ...result, ...val }), DEFAULT_OBJ);
    }
    return DEFAULT_OBJ;
  } catch (error) {
    console.error('getOnboardingStatistics error', error);
    return DEFAULT_OBJ;
  }
}
