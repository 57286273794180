import React, {
  Dispatch,
  SetStateAction,
  useState,
} from 'react';
import { Box, styled } from 'components';
import Typography from '@mui/material/Typography';
import isEmpty from 'lodash/isEmpty';
import { alpha } from '@mui/material';
import {
  MdArrowDropDown,
  MdArrowDropUp,
  MdArrowForward,
} from '../../../../icons';
import { IconButton } from '../../../../shared';
import TrashIcon from '../../../../icons/TrashIcon';
import { IPathLevel, PathGroupItem } from './types';

const StyledBox = styled(Box)(({ theme }) => ({
  '& .path-item-container': {
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${theme.palette.primary.light}`,
    borderRadius: 3,
    marginTop: 7.5,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  '& .path-item-title-row-container': {
    flexGrow: 1,
    cursor: 'pointer',
  },
  '& .path-item-title-row': {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 8,
    paddingTop: 8,
    paddingBottom: 8,
    svg: {
      marginLeft: 10,
    },
  },
  '& .path-item-action-container': {},
  '& .path-skills-abilities-container': {
    backgroundColor: alpha(
      theme.palette.button.primary.contrast,
      0.35,
    ),
  },
  '& .path-skills-abilities-row': {
    borderBottom: `1px solid ${theme.palette.primary.light}`,
    padding: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    img: {
      marginLeft: 10,
    },
  },
  '& .path-skills-abilities-title': {
    display: 'flex',
    alignItems: 'center',
  },
  '.path-skills-abilities-description': {
    [theme.breakpoints.down('desktopApp')]: {
      marginTop: theme.spacing(1.5),
    },
  },
  '& .path-skills-abilities-action-row': {
    display: 'flex',
    alignItems: 'center',
  },
  '& .delete-path-level': {
    height: 30,
    width: 30,
    svg: {
      fill: theme.palette.common.white,
    },
  },
  '.expandPathButton': {
    margin: theme.spacing(1),
    height: theme.spacing(4),
    width: theme.spacing(4),
  },
}));

export const PathLevel = ({
  pathGroupItem,
  handleDeletePathGroupItem = () => null,
  handleDeleteSkill = () => null,
  setChoosePathJobTypeIsOpen = () => null,
  canEdit = false,
  isActive = false,
  handleOpen,
}: {
  pathGroupItem:
    | PathGroupItem
    | (
        | IPathLevel
        | Dispatch<SetStateAction<Record<string, never>>>
      );
  handleDeletePathGroupItem?: (level: number) => void | null;
  setChoosePathJobTypeIsOpen?: (
    value: ((prevState: boolean) => boolean) | boolean,
  ) => void;
  canEdit?: boolean;
  isActive: boolean;
  handleDeleteSkill?: (arg: number) => void;
  handleOpen: (id: number) => void;
}) => {
  const [pathIsOpen, setPathIsOpen] = useState(true);
  const {
    jobTypeId,
    name,
    jobTypeName,
    skills = [],
    abilities = [],
    skillsAbilities,
    careerPathGroupItemId,
  } = pathGroupItem;

  const skillsAbilitiesGrouped = skillsAbilities || [
    ...abilities,
    ...skills,
  ];

  return (
    <StyledBox>
      <Box className={'path-item-container'}>
        <Box className={'path-item-title-row-container'}>
          <Box
            className={'path-item-title-row'}
            onClick={() =>
              canEdit ? setChoosePathJobTypeIsOpen(true) : null
            }
          >
            <Typography variant={'h3'}>
              {jobTypeId
                ? name || jobTypeName
                : 'Select Path Level Item'}
            </Typography>
            {canEdit && <MdArrowForward />}
          </Box>
        </Box>
        <Box className={'path-item-action-container'}>
          {jobTypeId && canEdit && (
            <IconButton
              onClick={handleDeletePathGroupItem}
              className={'delete-path-level'}
              variant={'filled-primary'}
              size={'small'}
            >
              <TrashIcon />
            </IconButton>
          )}
          <IconButton
            onClick={() => {
              handleOpen(careerPathGroupItemId || jobTypeId);
              setPathIsOpen(!pathIsOpen);
            }}
            variant={'filled-primary'}
            className={'expandPathButton'}
            size={'small'}
          >
            {pathIsOpen ? (
              <MdArrowDropDown />
            ) : (
              <MdArrowDropUp />
            )}
          </IconButton>
        </Box>
      </Box>
      <Box className={'path-skills-abilities-container'}>
        {isActive &&
          pathIsOpen &&
          !isEmpty(skillsAbilitiesGrouped) &&
          skillsAbilitiesGrouped.map((badge) => (
            <Box
              className={'path-skills-abilities-row'}
              key={badge.badgeUrl}
            >
              <Box>
                <Box className={'path-skills-abilities-title'}>
                  <Typography variant={'h3'}>
                    {badge.name}
                  </Typography>

                  <img
                    alt="skill or ability badge"
                    src={badge.badgeUrl}
                    style={{ height: 25, width: 25 }}
                  />
                </Box>
                <Typography
                  variant={'body2'}
                  className={'path-skills-abilities-description'}
                >
                  {badge.description}
                </Typography>
              </Box>
              <Box
                className={'path-skills-abilities-action-row'}
              >
                {canEdit && (
                  <IconButton
                    className={'delete-path-level'}
                    onClick={() => handleDeleteSkill(badge.id)}
                    variant={'filled-primary'}
                  >
                    <TrashIcon />
                  </IconButton>
                )}
              </Box>
            </Box>
          ))}
      </Box>
    </StyledBox>
  );
};
