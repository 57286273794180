import React from 'react';

const Alert = ({ fill }) => (
  <svg fill={fill} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2.75 8.4">
    <path
      className="cls-1"
      d="M223.46,306a8.09,8.09,0,0,1-.64,2.62l-.36,1h-.74l-.36-1a7.72,7.72,0,0,1-.65-2.62,1.38,1.38,0,0,1,2.75,0Zm-2.68,5.79a1.31,1.31,0,0,1,2.61,0,1.31,1.31,0,0,1-2.61,0Z"
      transform="translate(-220.71 -304.66)"
    />
  </svg>
);

export default Alert;
