import { gql } from '@apollo/client';

export const GET_TRAININGS = gql`
  query getTrainings($employerProfileId: Int, $employeeProfileId: Int!) {
    getTrainings(employerProfileId: $employerProfileId, employeeProfileId: $employeeProfileId) {
      id
      name
      link
      verified
      complete
      taken
      employeeProfileId
      employerProfileId
    }
  }
`;
