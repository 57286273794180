// structured this way because growth insinuates we'll need a db table for this eventually.
export const careerDevBlogs = [
  {
    title: 'Your Job History: Separating Fact From Fiction',
    link:
      'https://quickhire-82350.medium.com/your-job-history-separating-fact-from-fiction-e4369ac0ec5d',
    industryId: null
  },
  {
    title: 'Interviewing: Tips For Landing The Job',
    link: 'https://quickhire-82350.medium.com/interviewing-tips-for-landing-the-job-d8361fa19ad5',
    industryId: null
  },
  {
    title: 'How To Prepare For Leadership',
    link: 'https://medium.com/@quickhire-82350/how-to-prepare-for-leadership-1b29531aa6db',
    industryId: null
  },
  {
    title: 'Profile Perfect: How To Make A Great First Impression',
    link: 'https://medium.com/@quickhire-82350/profile-perfect-d021f1023add',
    industryId: null
  },
  {
    title: 'Making The Job Match',
    link: 'https://medium.com/@quickhire-82350/making-the-job-match-6026bb0ad68f',
    industryId: null
  },
  {
    title: 'Advance your career in manufacturing',
    link:
      'https://quickhire-82350.medium.com/advance-your-career-in-manufacturing-construction-transportation-36d153aad214',
    industryId: 11
  },
  {
    title: 'Advance your career in Delivery',
    link: 'https://quickhire-82350.medium.com/advance-your-career-in-sales-727028d41af0',
    industryId: 7
  },
  {
    title: 'Advance your career in Retail',
    link: 'https://quickhire-82350.medium.com/advance-your-career-in-sales-727028d41af0',
    industryId: 9
  },
  {
    title: 'Advance your career in the restaurant business',
    link: 'https://quickhire-82350.medium.com/advance-your-career-in-hospitality-8ab59b335ac4',
    industryId: 21
  },
  {
    title: 'Advance your career in administration',
    link: 'https://quickhire-82350.medium.com/advance-your-career-in-administration-d9777cf3743',
    industryId: 1
  },
  {
    title: 'Advance your career in hospitality',
    link: 'https://quickhire-82350.medium.com/advance-your-career-in-hospitality-8ab59b335ac4',
    industryId: 6
  },
  {
    title: 'Advance your career in warehousing',
    link:
      'https://quickhire-82350.medium.com/advance-your-career-in-manufacturing-construction-transportation-36d153aad214',
    industryId: 19
  },
  {
    title: 'Advance your career in sales',
    link: 'https://quickhire-82350.medium.com/advance-your-career-in-sales-727028d41af0',
    industryId: 20
  },
  {
    title: 'Advance your career in banking',
    link: 'https://quickhire-82350.medium.com/advance-your-career-in-sales-727028d41af0',
    industryId: 4
  },
  {
    title: 'Advance your career in construction',
    link:
      'https://quickhire-82350.medium.com/advance-your-career-in-manufacturing-construction-transportation-36d153aad214',
    industryId: 5
  },
  {
    title: 'Advance your career in health and wellness',
    link: 'https://medium.com/@quickhire-82350/advance-your-career-in-health-wellness-e095bd170172',
    industryId: 8
  },
  {
    title: 'Advance your career in beauty',
    link:
      'https://quickhire-82350.medium.com/advance-your-career-in-the-beauty-industry-b53fabfd1426',
    industryId: 3
  },
  {
    title: 'Advance your career in arts and media',
    link: 'https://quickhire-82350.medium.com/advance-your-career-in-the-arts-e345d4868de0',
    industryId: 2
  },
  {
    title: 'Advance your career in transportation',
    link:
      'https://quickhire-82350.medium.com/advance-your-career-in-manufacturing-construction-transportation-36d153aad214',
    industryId: 13
  },
  {
    title: 'Advance your career in healthcare',
    link: 'https://quickhire-82350.medium.com/advance-your-career-in-healthcare-9ac8423c2d17',
    industryId: 14
  },
  {
    title: 'Advance your career in customer service',
    link: 'https://quickhire-82350.medium.com/advance-your-career-in-sales-727028d41af0',
    industryId: 16
  }
];
