import { gql } from '@apollo/client';

const EMPLOYEE_RATING_BY_EMPLOYER_ID = gql`
  query employeeRatingByEmployerId($employerProfileId: Int) {
    employeeRatingByEmployerId(employerProfileId: $employerProfileId) {
      id
      active
      employeeProfileProfileId
      score
      employerProfileProfileId
    }
  }
`;

const EMPLOYEE_RATINGS_BY_EMPLOYER_ID = gql`
  query employeeRatingsByEmployerId {
    employeeRatingsByEmployerId {
      id
      active
      employeeProfileProfileId
      score
      employerProfileProfileId
    }
  }
`;

const EMPLOYEE_RATINGS_BY_EMPLOYEE_ID = gql`
  query employeeRatingsByEmployeeId {
    employeeRatingsByEmployeeId {
      id
      active
      employeeProfileProfileId
      score
      employerProfileProfileId
    }
  }
`;

const EMPLOYEES_BY_EMPLOYER_CODE = gql`
  query employeesByEmployerCode {
    employeesByEmployerCode {
      employerCodeId
      id
      email
      experience {
        experience_id
        name
        pos
        startDate
        endDate
        currentJob
      }
      employeeProfile {
        profile_id
        name
        imageUrl
      }
      rate
      careerDevelopment {
        curJobTypeId
        futureJobTypeId
        industryId
        currentlySearching
        showEmployerData
      }
    }
  }
`;

const EMPLOYER_STRUCTURE_CHART_BY_ID = gql`
  query employerStructureChartById {
    employerStructureChartById {
      id
      employerProfileProfileId
      name
      columns {
        id
        name
        index
        employeeProfiles {
          employeeProfileProfileId
          id
          index
          employeeProfile {
            name
            email
            imageUrl
            rate
            user_id
          }
          careerDevelopment {
            curJobTypeId
            futureJobTypeId
            industryId
            currentlySearching
            showEmployerData
          }
        }
      }
    }
  }
`;

const GET_JOB_TYPES_BY_INDUSTRY = gql`
  query jobTypesByIndustryId($industryId: Int) {
    jobTypesByIndustryId(industryId: $industryId) {
      id
      industryId
      name
    }
  }
`;

const RATING_REASONS = gql`
  query ratingReasons {
    ratingReasons {
      id
      reason
    }
  }
`;

const RATING_REASON_BY_EMPLOYER = gql`
  query ratingReasonsByEmployerProfile {
    ratingReasonsByEmployerProfile {
      reasonId
      id
      employeeProfileProfileId
    }
  }
`;

const EMPLOYEE_TENURE_BY_EMPLOYER = gql`
  query employeeTenureByEmployer {
    employeeTenureByEmployer {
      profile_id
      startDate
      curJobTypeId
      currentPosition
    }
  }
`;

const EMPLOYER_CODE_BY_EMPLOYER = gql`
  query employerCodeByEmployer {
    employerCodeByEmployer {
      code
    }
  }
`;

const EMPLOYER_ANALYTIC = gql`
  query getEmployerAnalytic($userId: Int) {
    getEmployerAnalytic(userId: $userId) {
      user_id
      analytic
    }
  }
`;

const EMPLOYER_ADMIN_PROFILES = gql`
  query getEmployerAdminProfiles(
    $userId: Int
    $limit: Int
    $page: Int
    $keywords: String
    $rejectedJobs: Int
    $acceptedJobs: Int
    $needActionJobs: Int
  ) {
    getEmployerAdminProfiles(
      userId: $userId
      limit: $limit
      page: $page
      keywords: $keywords
      rejectedJobs: $rejectedJobs
      acceptedJobs: $acceptedJobs
      needActionJobs: $needActionJobs
    ) {
      analytic
      imageUrl
      isAdmin
      name
      profilesCount
      user_id
    }
  }
`;

const EMPLOYER_CHURN_RATE = gql`
  query getChurnRateEmployer($employerProfileProfileId: Int) {
    getChurnRateEmployer(employerProfileProfileId: $employerProfileProfileId) {
      churnRateEmployer
    }
  }
`;

const EMPLOYER_HIRING_RATE = gql`
  query getHiringRateEmployer($employerProfileProfileId: Int) {
    getHiringRateEmployer(employerProfileProfileId: $employerProfileProfileId) {
      hireMetrics
    }
  }
`;

const EMPLOYER_HIRING_RATE_ADMIN = gql`
  query getHiringRateAdmin($employerProfileProfileId: Int) {
    getHiringRateAdmin(employerProfileProfileId: $employerProfileProfileId) {
      orgHireMetrics
    }
  }
`;

const EMPLOYER_COMPETITION_BREAKDOWN = gql`
  query getChartsScore($employerProfileProfileId: Int) {
    getChartsScore(employerProfileProfileId: $employerProfileProfileId) {
      marketPositionScore
      jobPostActivityScore
      hotJobs
    }
  }
`;

const EMPLOYER_COMPETITION_BREAKDOWN_ADMIN = gql`
  query getChartsScoreAdmin($employerProfileProfileId: Int) {
    getChartsScoreAdmin(employerProfileProfileId: $employerProfileProfileId) {
      marketPositionScore
      jobPostActivityScore
      hotJobs
    }
  }
`;

const EMPLOYER_CHART_SETTINGS = gql`
  query getChartSettings($employerProfileProfileId: Int) {
    getChartSettings(employerProfileProfileId: $employerProfileProfileId) {
      jobScores
      employeeSatisfaction
      companyBreakdown
      jobStats
      avgTen
      hiringRate
      equityScore
      competitionBreakdown
      employeeChurnRate
    }
  }
`;

export default {
  EMPLOYEE_RATINGS_BY_EMPLOYER_ID,
  EMPLOYEES_BY_EMPLOYER_CODE,
  EMPLOYER_STRUCTURE_CHART_BY_ID,
  EMPLOYEE_RATING_BY_EMPLOYER_ID,
  EMPLOYEE_RATINGS_BY_EMPLOYEE_ID,
  GET_JOB_TYPES_BY_INDUSTRY,
  RATING_REASONS,
  RATING_REASON_BY_EMPLOYER,
  EMPLOYEE_TENURE_BY_EMPLOYER,
  EMPLOYER_CODE_BY_EMPLOYER,
  EMPLOYER_ANALYTIC,
  EMPLOYER_ADMIN_PROFILES,
  EMPLOYER_HIRING_RATE,
  EMPLOYER_HIRING_RATE_ADMIN,
  EMPLOYER_COMPETITION_BREAKDOWN,
  EMPLOYER_COMPETITION_BREAKDOWN_ADMIN,
  EMPLOYER_CHURN_RATE,
  EMPLOYER_CHART_SETTINGS
};
