import { gql } from '@apollo/client';

export const RATE_EMPLOYEE_BY_EMPLOYER = gql`
  mutation rateByEmployerEmployee($employeeProfileId: Int!, $employerProfileId: Int!, $rate: Int!) {
    rateByEmployerEmployee(
      employeeProfileId: $employeeProfileId
      employerProfileId: $employerProfileId
      rate: $rate
    ) {
      id
      rate
    }
  }
`;
