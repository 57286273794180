import { gql } from '@apollo/client';

export const ADD_TRAININGS = gql`
  mutation addTrainings(
    $name: String
    $link: String
    $complete: Boolean
    $verified: Boolean
    $taken: Boolean
    $employerProfileId: Int
    $employeeProfileId: Int!
  ) {
    addTrainings(
      name: $name
      link: $link
      complete: $complete
      verified: $verified
      taken: $taken
      employerProfileId: $employerProfileId
      employeeProfileId: $employeeProfileId
    ) {
      id
      name
      link
      verified
      complete
      taken
      employeeProfileId
      employerProfileId
    }
  }
`;

export const POST_TRAININGS = gql`
  mutation postTrainings(
    $name: String
    $link: String
    $complete: Boolean
    $verified: Boolean
    $taken: Boolean
    $employerProfileId: Int
    $employeeProfileId: Int
    $id: Int!
  ) {
    postTrainings(
      name: $name
      link: $link
      complete: $complete
      verified: $verified
      taken: $taken
      employerProfileId: $employerProfileId
      employeeProfileId: $employeeProfileId
      id: $id
    ) {
      id
      name
      link
      verified
      complete
      taken
      employeeProfileId
      employerProfileId
    }
  }
`;

export const DELETE_TRAININGS = gql`
  mutation deleteTrainings($id: Int!) {
    deleteTrainings(id: $id) {
      id
      name
    }
  }
`;
