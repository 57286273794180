import { gql } from '@apollo/client';

export const GET_ALL_CC = gql`
  query getUserCards($token: String, $userId: Int) {
    getUserCards(token: $token, userId: $userId) {
      cardInfo
    }
  }
`;

export const GET_USER_COUPON = gql`
  query getUserCoupon($userId: Int) {
    getUserCoupon(userId: $userId) {
      couponId
      id
      isValid
      name
      percentOff
    }
  }
`;
