import { Box, styled } from 'components';
import Typography from '@mui/material/Typography';
import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import useBadgesQuery from 'hooks/operations/useBadgesQuery';
import { useMutation } from '@apollo/client';
import {
  ADD_SKILLS_TO_CAREER_PATHS,
  CREATE_CAREER_PATH_GROUP,
} from 'api';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { useNavigate } from 'react-router-dom';
import { getRoutes } from 'utils';
import { RetentionContext } from '../../../utils';
import {
  IPathLevel,
  IPathLevelJobType,
  IPathLevelSkillsAbilities,
} from './types';
import { MdAdd } from '../../../../icons';
import NewPathLevel from './NewPathLevel';
import { Button, Input, Spinner } from '../../../../shared';

const StyledBox = styled(Box)(() => ({
  '& .create-path-title-container': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  '& .create-path-name-container': {
    width: '100%',
    display: 'block',
    margin: '15px 0',
  },
  '& .create-path-action-container': {
    display: 'flex',
    alignItems: 'center',
    button: { marginLeft: 10 },
  },
  '& .create-new-level-row': {
    marginTop: 15,
  },
}));

const ManageOrganizationPaths = ({
  setManagingPaths,
}: {
  setManagingPaths: Dispatch<SetStateAction<boolean>>;
}) => {
  const ctx = useContext(RetentionContext);
  const navigate = useNavigate();
  const routes = getRoutes();

  const [pathLevelCount, setPathLevelCount] = useState(1);
  const [pathTitle, setPathTitle] = useState<string>('');
  const [pathLevels, setPathLevels] = useState<{
    [levelIndex: string]: IPathLevel | NonNullable<unknown>;
  }>({
    '1': {},
  });
  const [creatingCareerPathGroup, setCreatingCareerPathGroup] =
    useState(false);
  const [saveError, setSaveError] = useState<null | string>(
    null,
  );

  const [
    createCareerPathGroup,
    { error: createCareerPathGroupError },
  ] = useMutation(CREATE_CAREER_PATH_GROUP);

  const [
    createSkillsToCareerPaths,
    { error: createSkillsToCareerPathsError },
  ] = useMutation(ADD_SKILLS_TO_CAREER_PATHS);

  const {
    abilitiesBadges,
    fetchAbilityBadges,
    abilitiesBadgesLoading,
    skillsBadges,
    fetchSkillsBadges,
    skillsBadgesLoading,
  } = useBadgesQuery({
    skillBadgeVars: { limit: 200 },
    abilityBadgeVars: { limit: 200 },
  });

  useEffect(() => {
    fetchAbilityBadges();
    fetchSkillsBadges();
  }, []);

  const pathLevelsAreValid = (title, pl): boolean => {
    if (isEmpty(title)) {
      setSaveError('Title is required to create a path');
      return false;
    }
    const pathLevelValues = Object.values(pl);
    if (pathLevelValues?.length < 2) {
      setSaveError(
        'You need at least 2 path levels in order to save a path',
      );
      return false;
    }
    if (
      !pathLevelValues.every(
        (level) => level?.jobTypeId && level?.skillsAbilities,
      )
    ) {
      setSaveError('Each path must skills associated with it.');
      return false;
    }
    return true;
  };

  const createNewPath = async () => {
    if (!pathLevelsAreValid(pathTitle, pathLevels)) {
      return;
    }

    setCreatingCareerPathGroup(true);
    try {
      const resp = await createCareerPathGroup({
        variables: {
          careerPathGroupItems: Object.values(pathLevels).map(
            ({ jobTypeId }, index) => ({
              index,
              jobTypeId,
            }),
          ),
          organizationId: ctx.organizationId,
          name: isEmpty(pathTitle) ? null : pathTitle,
        },
      });

      const careerPathGroupId = get(
        resp,
        'data.createCareerPathGroup.careerPathGroup.id',
        null,
      );
      const careerPathGroupItems = get(
        resp,
        'data.createCareerPathGroup.careerPathGroupItems',
        null,
      );
      if (!careerPathGroupId || !careerPathGroupId)
        throw new Error('failed to create');

      const pathLevelsDictionary = Object.values(
        pathLevels,
      ).reduce(
        (acc, cur) => ({
          ...acc,
          [cur.jobTypeId]: cur,
        }),
        {},
      );

      const skillsAbilitiesPayload = careerPathGroupItems.map(
        (careerPathGroupItem) => {
          const jobTypeId =
            careerPathGroupItem.careerPathGroupItemJobType.id;

          const oldJobTypeId =
            careerPathGroupItem.careerPathGroupItemJobType
              .jobTypeRefId;

          const skillsGeneral = (
            pathLevelsDictionary[jobTypeId] ||
            pathLevelsDictionary[oldJobTypeId]
          ).skillsAbilities;

          const { skillIds, abilityIds } = skillsGeneral.reduce(
            (acc, cur) => {
              if (cur.abilitiesId) {
                acc.abilityIds.push(cur.abilitiesId);
              }
              if (cur.skillsId) {
                acc.skillIds.push(cur.skillsId);
              }
              return acc;
            },
            {
              skillIds: [],
              abilityIds: [],
            },
          );

          return {
            careerPathGroupItemId: careerPathGroupItem.id,
            hardSkills: {
              jobTypeId,
              skillIds,
            },
            softSkills: {
              jobTypeId,
              abilityIds,
            },
          };
        },
      );

      await Promise.all(
        skillsAbilitiesPayload.map(
          async (createSkillsAbilitiesPayload) =>
            createSkillsToCareerPaths({
              variables: createSkillsAbilitiesPayload,
            }),
        ),
      );

      setManagingPaths(false);
      setCreatingCareerPathGroup(false);
    } catch (err) {
      setSaveError(err);
      // set error and display it
    }
  };

  const handleCreateNewPathLevel = () => {
    setPathLevelCount(pathLevelCount + 1);
    setPathLevels({
      ...pathLevels,
      [pathLevelCount + 1]: {},
    });
  };

  const handlePathLevelUpdate =
    (level: number) =>
    (
      pathData: IPathLevelJobType | IPathLevelSkillsAbilities,
    ) => {
      setPathLevels({
        ...pathLevels,
        [level]: {
          ...pathLevels[level],
          ...pathData,
        },
      });
    };

  const handleDeletePathLevel = (level: number) => () => {
    const tempPathLevels = pathLevels;
    delete tempPathLevels[level];
    const reorderedPathLevels = Object.values(
      tempPathLevels,
    ).reduce(
      (acc, cur, idx) => ({
        ...acc,
        [idx + 1]: cur,
      }),
      {},
    );
    setPathLevelCount(pathLevelCount - 1);
    setPathLevels(reorderedPathLevels);
  };

  // if (
  //   createCareerPathGroupError ||
  //   createSkillsToCareerPathsError ||
  //   saveError
  // ) {
  //   return (
  //     <Box>
  //       There was an error saving your response, please try
  //       again.
  //     </Box>
  //   );
  // }

  return (
    <StyledBox>
      {creatingCareerPathGroup && (
        <Box>
          <Spinner />
        </Box>
      )}
      <Box className={'create-path-title-container'}>
        <Typography variant={'h2'}>Create new path</Typography>
        <Box className={'create-path-action-container'}>
          <Button
            onClick={() =>
              navigate(
                `${routes.employer.retention_portal.location}/${ctx.retentionPages.path}`,
              )
            }
            variant={'outlined-secondary'}
          >
            Cancel
          </Button>
          <Button
            onClick={createNewPath}
            variant={'filled-primary'}
          >
            Save
          </Button>
        </Box>
      </Box>
      <Box>
        {createCareerPathGroupError && (
          <Typography variant={'body1'} color={'error'}>
            There was an error saving the path, please try again
          </Typography>
        )}
        {createSkillsToCareerPathsError && (
          <Typography variant={'body1'} color={'error'}>
            There was an error saving skills to the path but the
            path was created, please navigate to paths and edit
            this path to add skills.
          </Typography>
        )}
        {saveError && (
          <Typography variant={'body1'} color={'error'}>
            {saveError}
          </Typography>
        )}
      </Box>
      <Box className={'create-path-name-container'}>
        <Input
          label={'Enter title of your path'}
          onChange={(e) => setPathTitle(e.target.value)}
        />
      </Box>
      <Box>
        <Typography variant={'h2'}>Path</Typography>
        {abilitiesBadgesLoading || skillsBadgesLoading ? (
          <Spinner />
        ) : (
          <Box>
            {Object.values(pathLevels).map((pathLevel, i) => (
              <NewPathLevel
                key={`pathLevelItem-${pathLevel.careerPathGroupItemId}`}
                count={i + 1}
                skillsBadges={skillsBadges}
                abilitiesBadges={abilitiesBadges}
                pathLevel={pathLevel}
                handlePathLevelUpdate={handlePathLevelUpdate(
                  i + 1,
                )}
                handleDeletePathLevel={handleDeletePathLevel(
                  i + 1,
                )}
              />
            ))}
            <Box className={'create-new-level-row'}>
              <Button
                variant={'filled-primary'}
                onClick={handleCreateNewPathLevel}
              >
                <MdAdd />
                <Typography>Add a level</Typography>
              </Button>
              {ctx.accessOrganization && (
                <>
                  <Button
                    sx={{ ml: 2 }}
                    variant={'outlined-primary'}
                    onClick={() =>
                      navigate(
                        `${routes.employer.retention_portal.location}/${ctx.retentionPages.pathmanager}/${ctx.retentionPages.createcustompath}`,
                      )
                    }
                  >
                    <Typography>New Path Item</Typography>
                  </Button>
                  <Button
                    sx={{ ml: 2 }}
                    variant={'outlined-primary'}
                    onClick={() =>
                      navigate(
                        `${routes.employer.retention_portal.location}/${ctx.retentionPages.pathmanager}/${ctx.retentionPages.createcustomskill}`,
                      )
                    }
                  >
                    <Typography>New Skill</Typography>
                  </Button>
                </>
              )}
            </Box>
          </Box>
        )}
      </Box>
    </StyledBox>
  );
};

export default ManageOrganizationPaths;
