import { getRoutes } from 'utils';
import { GRID_LAYOUT as GRID_L, LIST_LAYOUT as LIST_L } from '../shared/LayoutViewController';

export const GRID_LAYOUT = GRID_L;
export const LIST_LAYOUT = LIST_L;

const ROUTES = getRoutes();

export const CHAT_MESSAGE_TEMPLATE =
  'Thanks for submitting your application to our open position. We like your profile and would like to consider you for the job. When are you available to chat?';

export const QH_MATCHES_TAB = 'qh_matches';
export const APPLICANTS_TAB = 'applicants';
export const APPROVED_TAB = 'approved';
export const REJECTED_TAB = 'rejected';
export const STARRED_TAB = 'starred';
export const NEXT_STEPS_TAB = 'nextSteps';
export const QH_PATH = ROUTES.employer.job_match;
export const APPLICANTS_PATH = ROUTES.employer.job;
export const APPROVED_PATH = ROUTES.employer.job_approved;
export const REJECTED_PATH = ROUTES.employer.job_rejected;
export const STARRED_PATH = ROUTES.employer.job_starred;
export const NEXT_STEPS_PATH = ROUTES.employer.job_next_steps;
export const TABS = [
  // { path: QH_PATH, label: 'Matches', name: QH_MATCHES_TAB },
  { path: APPLICANTS_PATH, label: 'Applicants', label_2: 'Applicants', name: APPLICANTS_TAB },
  { path: APPROVED_PATH, label: 'Approved', label_2: 'Hired', name: APPROVED_TAB },
  { path: REJECTED_PATH, label: 'Rejected', label_2: 'Rejected', name: REJECTED_TAB },
  { path: STARRED_PATH, label: 'Starred', label_2: 'Starred', name: STARRED_TAB },
  { path: NEXT_STEPS_PATH, label: 'Next Steps', label_2: 'Next Steps', name: NEXT_STEPS_TAB }
];
export const ACCEPTED_STATUS = 'accepted';
export const REJECTED_STATUS = 'rejected';
export const STARRED_STATUS = 'starred';
export const NEXT_STATUS = 'nextSteps';
