import React from 'react';
import PropTypes from 'prop-types';
import { qaAttr } from 'utils';
import { Box, styled } from 'components';
import {
  AddressForm,
  CompanyNameForm,
  CompanyImageForm,
  EmployerPhoneForm,
  EmployeesCountForm,
  IndustryForm
  // ZipCodeForm
} from 'components/Onboarding/employer';
import styles from 'styles/Onboarding';

function EmployerOnboardingSteps(props) {
  const { form, onFormChange, onGoToNextStep, onFinish, step } = props;

  const renderContent = () => {
    let title = '';
    let content = null;
    const scrollableContent = step === 'industry';
    const commonProps = {
      onChange: onFormChange,
      onGoToNextStep,
      form
    };

    switch (step) {
      case 'companyName': {
        title = 'Company Name';
        content = <CompanyNameForm {...commonProps} />;
        break;
      }
      case 'companyImage': {
        title = 'Company Profile Image';
        content = <CompanyImageForm {...commonProps} />;
        break;
      }
      case 'employeesCount': {
        title = 'How many employees do you have?';
        content = <EmployeesCountForm {...commonProps} />;
        break;
      }
      case 'address': {
        title = 'Address';
        content = <AddressForm {...commonProps} />;
        break;
      }
      // case 'zipCode': {
      //   title = 'ZIP Code';
      //   content = <ZipCodeForm {...commonProps} />;
      //   break;
      // }
      case 'phone': {
        title = 'Your phone number';
        content = <EmployerPhoneForm {...commonProps} />;
        break;
      }
      case 'industry': {
        title = 'Industry';
        content = <IndustryForm {...commonProps} onFinish={onFinish} />;
        break;
      }
      default:
    }

    return (
      <div className="stepContent">
        <Box
          component="h1"
          maxWidth={350}
          mx="auto"
          mb="20px"
          className="pageTitle"
          {...qaAttr('onboarding-form-title')}
        >
          {title}
        </Box>
        <Box
          width="100%"
          maxWidth={320}
          mx="auto"
          display="flex"
          flexDirection="column"
          overflow={scrollableContent ? 'auto' : 'initial'}
        >
          {content}
        </Box>
      </div>
    );
  };

  return renderContent();
}

EmployerOnboardingSteps.propTypes = {
  form: PropTypes.objectOf(PropTypes.any).isRequired,
  onFormChange: PropTypes.func.isRequired,
  onGoToNextStep: PropTypes.func.isRequired,
  onFinish: PropTypes.func.isRequired,
  step: PropTypes.string.isRequired
};

const StyledRoot = styled(EmployerOnboardingSteps)(styles);

export default StyledRoot;
