/* global analytics */
const trackPage = (params = []) => {
  if (analytics) analytics.page(...params);
};

const identify = (traits = {}, userId) => {
  const uId = userId || localStorage.getItem('userId');

  if (analytics) {
    analytics.identify(uId, traits);
  }
};

const group = (traits = {}, groupId) => {
  if (analytics && groupId) {
    analytics.group(groupId, traits);
  }
};

const reset = () => {
  if (analytics) analytics.reset();
};

const track = (event, properties) => {
  if (analytics && event) analytics.track(event, properties);
};

export { group, identify, reset, track, trackPage };
