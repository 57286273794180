import { gql } from '@apollo/client';

export const RATING_REASONS = gql`
  query ratingReasons {
    ratingReasons {
      reason
      id
    }
  }
`;

export const RATING_REASONS_TO_EMP_PROFILE_BY_EXPERIENCE_ID = gql`
  query ratingReasonsToEmpProfileByExperienceId($experienceId: Int) {
    ratingReasonsToEmpProfileByExperienceId(experienceId: $experienceId) {
      employeeProfileProfileId
      employerProfileProfileId
      reasonId
    }
  }
`;

export const EMPLOYEE_PROFILE_TO_EMPLOYER_CODE = gql`
  query codeAssociationByExperienceId($experienceId: Int) {
    codeAssociationByExperienceId(experienceId: $experienceId) {
      id
      experienceId
      employerCodeId
      employeeProfileProfileId
    }
  }
`;

export const EMPLOYEE_RATING_BY_EXP_ID = gql`
  query employerRatingByExperienceId($experienceId: Int) {
    employerRatingByExperienceId(experienceId: $experienceId) {
      id
      employeeProfileProfileId
      employerProfileProfileId
      score
    }
  }
`;
