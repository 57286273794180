import React from 'react';

const Trash = ({ fill }) => (
  <svg fill={fill} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8.07 8.61">
    <path
      className="cls-1"
      d="M148.21,306.61h-.31a.31.31,0,0,1-.35-.32c0-.2.12-.34.35-.34h2v-.76a.53.53,0,0,1,.55-.59q1.14,0,2.28,0a.56.56,0,0,1,.54.59c0,.24,0,.48,0,.76h2a.33.33,0,0,1,0,.66H155c-.06.68-.13,1.33-.19,2l-.42,4.23c0,.28-.13.38-.4.38h-4.71c-.3,0-.4-.09-.43-.39l-.39-4.08C148.35,308,148.28,307.34,148.21,306.61Zm6.07,0h-5.39c0,.09,0,.16,0,.24.18,1.79.35,3.59.52,5.39,0,.22.07.28.3.28q1.86,0,3.72,0c.2,0,.27-.05.29-.26l.39-4C154.18,307.73,154.23,307.19,154.28,306.63Zm-3.72-.69h2a4.86,4.86,0,0,0,0-.54.25.25,0,0,0-.16-.14h-1.72a.28.28,0,0,0-.16.14A4.86,4.86,0,0,0,150.56,305.94Z"
      transform="translate(-147.55 -304.59)"
    />
    <path
      className="cls-1"
      d="M153.09,309.5V311c0,.25-.13.39-.33.38s-.33-.14-.33-.39q0-1.45,0-2.91c0-.24.13-.39.33-.39s.32.14.33.4Z"
      transform="translate(-147.55 -304.59)"
    />
    <path
      className="cls-1"
      d="M150.07,309.48v-1.41c0-.28.12-.43.33-.43s.34.15.34.44v2.83c0,.27-.12.42-.33.42s-.33-.13-.34-.41Z"
      transform="translate(-147.55 -304.59)"
    />
    <path
      className="cls-1"
      d="M151.91,309.48v1.44c0,.27-.11.41-.33.41s-.33-.14-.33-.41v-2.86c0-.27.12-.42.33-.42s.33.15.33.42Z"
      transform="translate(-147.55 -304.59)"
    />
  </svg>
);

export default Trash;
