import { addAlpha } from '../utils';

const badgeCardStyles = ({ theme, completed }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '16px',
  border: `2px solid ${
    completed ? 'transparent' : theme.palette.secondary.main
  }`,
  background: completed
    ? addAlpha(theme.palette.primary.main, 0.25)
    : 'transparent',
  borderRadius: '8px',
  justifyContent: 'center',
  alignItems: 'center',
  aspectRatio: '1 / 1',
  width: '100%',
  textAlign: 'center',
  height: '100%',
  [theme.breakpoints.down('tablet')]: {
    aspectRatio: 'auto',
  },
  '& .badgeTitleWrapper': {
    display: 'flex',
    minHeight: '96px',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('tablet')]: {
      minHeight: 0,
    },
    '& .positioningWrapper': {
      display: 'flex',
      flexGrow: 1,
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  '& img': {
    width: '48px',
    height: '48px',
  },
  '& Typography': {
    color: 'red',
  },
  '& Button': {
    marginTop: '8px',
    backgroundColor: 'none',
    border: `2px solid ${theme.palette.secondary.main}`,
    color: theme.palette.text.primary,
  },
  '& .pending': {
    backgroundColor: theme.palette.button.primary.disabled,
    border: `2px solid ${theme.palette.button.primary.disabled}`,
    color: theme.palette.button.primary.contrastText,
    minWidth: '150px',
    opacity: 0.5,
    pointerEvents: 'none',
  },
  '& .completed': {
    backgroundColor: 'none',
    color: theme.palette.text.primary,
    minWidth: '150px',
    pointerEvents: 'none',
  },
  '& .errorText': {
    marginTop: '8px',
    color: theme.palette.secondary.main,
  },
  '& .requested': {
    backgroundColor: 'none',
    color: theme.palette.text.primary,
    minWidth: '150px',
  },
});

export default badgeCardStyles;
