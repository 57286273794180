export const criteriaFilters = [
  // {
  //   criteria: 'industry',
  //   label: "By Industry:",
  //   fields: [
  //     { value: 1, name: "Administrative" },
  //     { value: 2, name: "Arts" },
  //     { value: 3, name: "Beauty" },
  //     { value: 4, name: "Banking" },
  //     { value: 5, name: "Construction" },
  //     { value: 6, name: "Hospitality" },
  //     { value: 7, name: "Delivery" },
  //     { value: 8, name: "Health & Wellness" },
  //     { value: 9, name: "Retail" },
  //     { value: 10, name: "Manufacturing" },
  //     { value: 11, name: "Transportation" },
  //     { value: 12, name: "Healthcare" },
  //     { value: 13, name: "Maintenance" },
  //     { value: 14, name: "Customer Service" },
  //     { value: 15, name: "Education" },
  //     { value: 16, name: "Non-Profit" },
  //     { value: 17, name: "Warehouse" },
  //     { value: 18, name: "Sales" },
  //     { value: 19, name: "Restaurant" },
  //     { value: 20, name: "Game Development" },
  //     { value: 21, name: "Movie maker" },
  //   ],
  // },
  {
    criteria: 'location',
    label: 'By Location:',
    fields: [
      { value: 1, name: 'within 5 miles' },
      { value: 2, name: 'within 10 miles' },
      { value: 3, name: 'within 25 miles' },
      { value: 4, name: 'within 50 miles' },
      { value: 5, name: 'within 100 miles' }
    ]
  },
  // {
  //   criteria: 'skills',
  //   label: 'By Skills',
  //   fields: [
  //     { value: 1, name: 'Customer Service' },
  //     { value: 2, name: 'Data Entry' },
  //     { value: 3, name: 'Event Planning' },
  //     { value: 4, name: 'Electricity' },
  //     { value: 5, name: 'Merchandising' },
  //     { value: 6, name: 'Microsoft Office' },
  //     { value: 7, name: 'Management' },
  //     { value: 8, name: 'Project Management' },
  //     { value: 9, name: 'Machinery/Machining' },
  //     { value: 10, name: 'Welding' },
  //     { value: 11, name: 'Customer Service' },
  //     { value: 12, name: 'Data Entry' },
  //     { value: 13, name: 'Event Planning' },
  //     { value: 14, name: 'Electricity' },
  //     { value: 15, name: 'Merchandising' },
  //     { value: 16, name: 'Microsoft Office' },
  //     { value: 17, name: 'Management' },
  //     { value: 18, name: 'Project Management' },
  //     { value: 19, name: 'Machinery/Machining' },
  //     { value: 20, name: 'Welding' }
  //   ]
  // },
  {
    criteria: 'careerHealthScore',
    label: 'By Career Health Score',
    fields: [
      {
        name: '> 50',
        value: 1
      },
      {
        name: '> 60',
        value: 2
      },
      {
        name: '> 70',
        value: 3
      },
      {
        name: '> 80',
        value: 4
      },
      {
        name: '> 90',
        value: 5
      }
    ]
  },
  {
    criteria: 'experience',
    label: 'By Experience',
    fields: [
      {
        name: 'more than 1',
        value: 1
      },
      {
        name: 'more than 2',
        value: 2
      },
      {
        name: 'more than 3',
        value: 3
      }
    ]
  }
];
