import React from 'react';

const CommonUIContext = React.createContext({
  manageModals: () => {},
  closeUpdatePlanModal: () => {},
  closePaymentModal: () => {},
  closeChatModal: () => {},
  openUpdatePlanModal: () => {},
  openPaymentModal: () => {},
  openChatModal: () => {},
  setEmployerJobsFilter: () => {},
  setEmployeeJobsTab: () => {},
  employerJobsFilter: 0,
  employeeJobsTab: 0,
  hirePromptWasShown: false,
  newJobPromptWasShown: false
});

export default CommonUIContext;
