import { gql } from '@apollo/client';

export const POST_EMPLOYEE_CHAT = gql`
  mutation postEmployeeChat(
    $jobsId: Int!
    $messageFrom: Int!
    $message: String!
    $messageTo: Int!
    $usersUserId: Int!
  ) {
    postEmployeeChat(
      jobsId: $jobsId
      messageFrom: $messageFrom
      message: $message
      messageTo: $messageTo
      usersUserId: $usersUserId
    ) {
      message
    }
  }
`;

export const SET_EMPLOYER_CHAT = gql`
  mutation setEmployerChat(
    $jobsId: Int!
    $messageFrom: Int!
    $message: String!
    $messageTo: Int!
    $usersUserId: Int!
  ) {
    setEmployerChat(
      jobsId: $jobsId
      messageFrom: $messageFrom
      message: $message
      messageTo: $messageTo
      usersUserId: $usersUserId
    ) {
      message
    }
  }
`;
