import { gql } from '@apollo/client';

export const GET_BASE_PLANS = gql`
  mutation basePlans {
    basePlans {
      id
      name
      period
      price
      restrictions
      type
    }
  }
`;

export const REQUEST_PLAN_UPGRADE = gql`
  mutation postUpgradePlanEmployer($planEmployerId: Int!) {
    postUpgradePlanEmployer(planEmployerId: $planEmployerId) {
      autorenew
      endDate
      id
      name
      paid
      price
      planEmployerId
      restrictions
      startDate
      status
    }
  }
`;

export const PAUSE_PLAN = gql`
  mutation pausePlan($planId: Int!, $isPaused: Boolean) {
    pausePlan(planId: $planId, isPaused: $isPaused) {
      name
      paused
    }
  }
`;

export const CANCEL_PLAN = gql`
  mutation cancelPlan($planId: Int!) {
    cancelPlan(planId: $planId) {
      name
    }
  }
`;

export const PAY = gql`
  mutation postPay(
    $token: String
    $cardId: String
    $isSave: Boolean
    $planId: Int
    $coupon: String
  ) {
    postPay(token: $token, cardId: $cardId, isSave: $isSave, planId: $planId, coupon: $coupon) {
      autorenew
      endDate
      id
      name
      paid
      planEmployerId
      price
      restrictions
      startDate
      status
      transactionId
      employerId
      planEmployer {
        name
      }
    }
  }
`;

export const CREATE_CC = gql`
  mutation createUserCard($cardDetails: String, $userId: Int) {
    createUserCard(cardDetails: $cardDetails, userId: $userId) {
      cardInfo
      customerId
      cardId
      token
      sourceId
    }
  }
`;

export const UPDATE_CC = gql`
  mutation updateUserCard($cardId: String, $userId: Int, $params: String) {
    updateUserCard(cardId: $cardId, userId: $userId, params: $params) {
      cardInfo
    }
  }
`;

export const DELETE_CC = gql`
  mutation deleteUserCard($sourceId: String, $userId: Int) {
    deleteUserCard(sourceId: $sourceId, userId: $userId) {
      cardInfo
    }
  }
`;
