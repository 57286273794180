import Typography from '@mui/material/Typography';
import React, {
  SyntheticEvent,
  useContext,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { get } from 'lodash';
import { useMutation } from '@apollo/client';
import { Box, styled } from '../../../../components';
import { Button, Input } from '../../../../components/shared';
import { RetentionContext } from '../../../../components/Retention/utils';
import { getRoutes } from '../../../../utils';
import { MdChevronRight } from '../../../../components/icons';
import { IndustryModal } from '../../../../components/dialogs';
import { CREATE_JOB_TYPE_BY_ORGANIZATION } from '../../../../api';

const StyledBox = styled(Box)(({ theme }) => ({
  '& .create-path-title-container': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  '& .create-path-action-container': {
    display: 'flex',
    alignItems: 'center',
    button: { marginLeft: 10 },
  },
  '& .badge-container': {
    display: 'flex',
    flexWrap: 'wrap',
    maxHeight: 330,
    overflowY: 'scroll',
    '& > div': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      img: {
        maxWidth: 100,
      },
    },
  },
  '& .badgeUrlContainer': {
    border: `1px solid transparent`,
    borderRadius: 4,
    '&.active': {
      borderColor: theme.palette.secondary.light,
    },
  },
}));

const CreateCustomPathItems = () => {
  const ctx = useContext(RetentionContext);
  const navigate = useNavigate();
  const routes = getRoutes();
  const [pathItemTitle, setPathItemTitle] = useState<string>('');
  const [pathItemDescription, setPathItemDescription] =
    useState<string>('');
  const [isIndustryModalOpen, setIsIndustryModalOpen] =
    useState();
  const [formAttrs, setFormAttrs] = useState({
    industry: [],
  });
  const [formError, setFormError] = useState<string | null>(
    null,
  );

  const [createJobTypeByOrganization, { loading }] = useMutation(
    CREATE_JOB_TYPE_BY_ORGANIZATION,
    {
      fetchPolicy: 'no-cache',
    },
  );

  const handleSetPathItemTitle = (e: SyntheticEvent) => {
    setPathItemTitle(e.target.value);
  };

  const handleSetPathItemDescription = (e: SyntheticEvent) => {
    setPathItemDescription(e.target.value);
  };

  const industry = get(formAttrs, 'industry[0]', null);

  const createNewPath = async () => {
    if (!pathItemTitle) setFormError('Title is required');
    if (!pathItemDescription)
      setFormError('Description is required');
    if (!industry) setFormError('Industry is required');
    if (!pathItemTitle || !pathItemDescription || !industry)
      return;
    const pathItem = await createJobTypeByOrganization({
      variables: {
        name: pathItemTitle,
        description: pathItemDescription,
        industryId: industry.id,
      },
    });
    if (pathItem) {
      navigate(
        `${routes.employer.retention_portal.location}/${ctx.retentionPages.pathmanager}/${ctx.retentionPages.createcustomskill}`,
        { state: { pathItem } },
      );
    }
  };

  const onFormChange = (ind: { industry: Array<never> }) => {
    setFormAttrs({ industry: [ind.industry[0]] });
  };

  return (
    <StyledBox>
      <Box>
        <Box className={'create-path-title-container'}>
          <Typography variant={'h2'}>
            Create custom path item
          </Typography>
          <Box className={'create-path-action-container'}>
            <Button
              onClick={() =>
                navigate(
                  `${routes.employer.retention_portal.location}/${ctx.retentionPages.pathmanager}`,
                )
              }
              variant={'outlined-secondary'}
              disabled={loading}
            >
              Cancel
            </Button>
            <Button
              onClick={createNewPath}
              variant={'filled-primary'}
              disabled={loading}
            >
              Save
            </Button>
          </Box>
        </Box>
        {formError && (
          <Box>
            <Typography variant={'body1'} color={'error'}>
              {formError}
            </Typography>
          </Box>
        )}
        <Box>
          <Box sx={{ mt: 2 }}>
            <Typography variant={'h3'}>Name</Typography>
            <Input
              value={pathItemTitle}
              onChange={handleSetPathItemTitle}
              disabled={loading}
            />
          </Box>
          <Box sx={{ mt: 2 }}>
            <Typography variant={'h3'}>Description</Typography>
            <Typography variant={'body1'}>
              Create a description that your users will use to
              help guide them to complete this goal.
            </Typography>
            <Input
              value={pathItemDescription}
              onChange={handleSetPathItemDescription}
              multiline
              inputProps={{ rowsmax: 5 }}
              inputClassName="textarea"
              htmlInputClassName="textareaInput"
              labelClassName="label"
              disabled={loading}
            />
          </Box>
          <Box sx={{ mt: 2, mb: 2 }}>
            <Typography>
              Choose an industry that the path item is affiliated
              with. Only 1 will be used.
            </Typography>
          </Box>
          <Button
            variant="text"
            endIcon={<MdChevronRight color="primary" />}
            onClick={() => setIsIndustryModalOpen(true)}
            testID="industry-modal-trigger-button"
          >
            Add Industry
          </Button>
          {industry && (
            <Typography variant={'h6'}>
              {industry.name}
            </Typography>
          )}
          <IndustryModal
            isOpen={isIndustryModalOpen}
            formAttrs={formAttrs}
            onChange={onFormChange}
            onClose={() => setIsIndustryModalOpen(false)}
          />
        </Box>
      </Box>
    </StyledBox>
  );
};

export default CreateCustomPathItems;
