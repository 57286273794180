import { gql } from '@apollo/client';

export const GET_QUIZZES = gql`
  query getQuiz {
    getQuiz {
      name
      value
    }
  }
`;

export const GET_CURRENCIES = gql`
  query getAllCurrencies {
    getAllCurrencies {
      id
      name
    }
  }
`;

export const GET_COUNTRIES = gql`
  query getAllCountries {
    getAllCountries {
      code
      id
      name
    }
  }
`;

export const GET_STATES = gql`
  query getAllStates {
    getAllStates {
      id
      name
      code
      country {
        name
        code
      }
    }
  }
`;

export const GET_PAY_PERIODS = gql`
  query getAllPayPeriods {
    getAllPayPeriods {
      id
      name
    }
  }
`;

export const GET_INDUSTRY = gql`
  query getIndustry {
    getIndustry {
      industry_id
      name
    }
  }
`;

export const GET_SKILLS = gql`
  query getSkills {
    getSkills {
      skill_id
      name
    }
  }
`;

export const IS_NEW_MESSAGES = gql`
  query isNewMessages($messageTo: Int!, $type: String!) {
    isNewMessages(messageTo: $messageTo, type: $type) {
      message
    }
  }
`;

export const GET_NEW_MESSAGES = gql`
  query getNewMessages($messageTo: Int!, $type: String!) {
    getNewMessages(messageTo: $messageTo, type: $type) {
      id
      jobsId
      jobs {
        employerProfile {
          imageUrl
          name
        }
      }
      message
      messageTo
      messageFrom
      users {
        employeeProfile {
          name
          imageUrl
        }
      }
    }
  }
`;

export const GET_JOB_TYPES = gql`
  query GetJobsTypes(
    $organizationId: Int
    $includeOrgJobTypes: Boolean
  ) {
    getJobsTypes(
      organizationId: $organizationId
      includeOrgJobTypes: $includeOrgJobTypes
    ) {
      id
      name
      industryId
      createdAt
      updatedAt
      skillsBadges
      abilitiesBadges
      jobTypeRefId
      description
      organizationId
    }
  }
`;

export const GET_JOB_TYPES_BY_INDUSTRY = gql`
  query jobTypesByIndustryId($industryId: Int) {
    jobTypesByIndustryId(industryId: $industryId) {
      id
      industryId
      name
    }
  }
`;

export const GET_EMPLOYEES_BY_INDUSTRY_ID = gql`
  query employeesByIndustryId($industryId: Int) {
    employeesByIndustryId(industryId: $industryId) {
      experience {
        currentJob
        name
        pos
      }
      imageUrl
      name
      profile_id
      user_id
    }
  }
`;

export const GET_EMPLOYEES_BY_JOB_TYPE_ID = gql`
  query employeesByJobTypeId($jobTypeId: Int) {
    employeesByJobTypeId(jobTypeId: $jobTypeId) {
      experience {
        currentJob
        name
        pos
      }
      imageUrl
      name
      profile_id
      user_id
    }
  }
`;

export const GET_ABILITIES_TYPES_BY_BADGE_ID = gql`
  query getAbilitiesTypesByBadgeId($abilitiesBadgeId: Int!) {
    getAbilitiesTypesByBadgeId(
      abilitiesBadgeId: $abilitiesBadgeId
    ) {
      id
      name
      description
    }
  }
`;

export const GET_SKILLS_TYPES_BY_BADGE_ID = gql`
  query getSkillsTypesByBadgeId($skillsBadgeId: Int!) {
    getSkillsTypesByBadgeId(skillsBadgeId: $skillsBadgeId) {
      id
      name
      description
    }
  }
`;

export const GET_BADGES_BY_JOB_TYPE = gql`
  query getBadgesByJobType($id: Int) {
    getBadgesByJobType(id: $id) {
      id
      name
      industryId
      skillsBadges
      abilitiesBadges
    }
  }
`;
