import differenceInCalendarYears from 'date-fns/differenceInCalendarYears';
import parse from 'date-fns/parse';

function getAgeByBirthDate(birthDate) {
  let age = '';

  if (birthDate && /^\d{4}(-\d{2}){2}$/.test(birthDate)) {
    const date = parse(birthDate, 'yyyy-MM-dd', new Date());
    age = date ? differenceInCalendarYears(new Date(), date) : '';
  }

  return age;
}

export default getAgeByBirthDate;
