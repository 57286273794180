export const CHART_COLORS = ['#49469D', '#F99157', '#7BB8FF', '#FFB80E', '#CECBDD', '#ECECF5'];

export const getChartData = (employees, jobTypes) => {
  const chartInfo = employees.reduce((acc, employee) => {
    const { curJobTypeId } = employee?.careerDevelopment || {};
    return curJobTypeId
      ? {
          ...acc,
          [curJobTypeId]: acc[curJobTypeId] ? acc[curJobTypeId] + 1 : 1
        }
      : { ...acc, 'N/A': acc['N/A'] ? acc['N/A'] + 1 : 1 };
  }, {});

  const chartLabels = Object.keys(chartInfo).map((key) =>
    !Number.isNaN(Number(key)) ? jobTypes[key].name : key
  );
  const chartData = Object.values(chartInfo);
  return { chartData, chartLabels };
};

export const getBarChartData = ({ rootLabel, labels, data }) => ({
  labels,
  datasets: [
    {
      label: rootLabel,
      data,
      // base: 2,
      barThickness: 12,
      borderSkipped: 'middle',
      borderRadius: 20,
      backgroundColor: CHART_COLORS
      // backgroundColor: ['#5D5CA9', '#5D5CA9', '#5D5CA9', '#5D5CA9', '#5D5CA9', '#5D5CA9']
    }
  ]
});

export const calculateAverageTenure = (months) =>
  (months.reduce((acc, cur) => acc + cur, 0) / months.length).toFixed(2);
