import { gql } from '@apollo/client';

export const POST_USER_GENDER = gql`
  mutation postEmployeeGender($gender: String!) {
    postEmployeeGender(gender: $gender) {
      user_id
      profile_id
      gender
    }
  }
`;

export const POST_USER_RACE = gql`
  mutation postEmployeeRace($race: String!) {
    postEmployeeRace(race: $race) {
      user_id
      profile_id
      race
    }
  }
`;

export const POST_USER_RESUME = gql`
  mutation postEmployeeResume($website: String!) {
    postEmployeeResume(website: $website) {
      user_id
      profile_id
      website
    }
  }
`;

export const POST_USER_NAME = gql`
  mutation postEmployeeName($name: String!) {
    postEmployeeName(name: $name) {
      profile_id
      user_id
      name
    }
  }
`;

export const POST_USER_PHONE = gql`
  mutation postEmployeePhone($phone: String!) {
    postEmployeePhone(phone: $phone) {
      profile_id
      user_id
      phone
    }
  }
`;

export const POST_USER_BIRTHDATE = gql`
  mutation postEmployeeBirthDate($birth_date: String!) {
    postEmployeeBirthDate(birth_date: $birth_date) {
      profile_id
      user_id
      birth_date
    }
  }
`;

export const POST_EMPLOYEE_SKILLS = gql`
  mutation postEmployeeSkills($skills: [SkillsInputType]) {
    postEmployeeSkills(skills: $skills) {
      user_id
    }
  }
`;

export const POST_USER_ZIP = gql`
  mutation postEmployeeZip($zip: String!) {
    postEmployeeZip(zip: $zip) {
      profile_id
      user_id
      zip
    }
  }
`;
