import { gql } from '@apollo/client';
import { ADMIN_TO_EMPLOYER_PROFILE_FRAGMENT } from '../fragments';

export const GET_EMPLOYER_PROFILE = gql`
  query employerProfile($userId: Int!) {
    employerProfile(userId: $userId) {
      address
      allowPlan
      allowJobPosting
      createdAt
      city
      countryId
      description
      email
      isAdmin
      imageUrl
      industry {
        id: industry_id
        name
      }
      jobCount
      name
      newSubscriptionPlan {
        autorenew
        endDate
        id
        name
        paid
        paused
        pausedDate
        planEmployerId
        price
        restrictions
        startDate
        status
      }
      phone
      profile_id
      profileWizard
      restrictions
      regStep
      size
      subscriptionPlan {
        autorenew
        endDate
        id
        name
        paid
        paused
        pausedDate
        planEmployerId
        price
        restrictions
        startDate
        status
      }
      stateId
      timeZone
      trialTimePlan
      user_id
      website
      zip
      updatedAt
      showPlanModal
      inappNewApplicants
      emailNewApplicants
      inappInboxMessages
      emailInboxMessages
      inappTimeRespondEnds
      emailTimeRespondEnds
      mergeApiId
      mergeAccountToken
      mergeCompanyId
      accountSecret
    }
  }
`;

export const GET_ADMIN_ACCOUNTS = gql`
  ${ADMIN_TO_EMPLOYER_PROFILE_FRAGMENT}
  query getAdminAccounts($userId: Int!) {
    getAdminAccounts(userId: $userId) {
      ...AdminToEmployerProfileFragment
    }
  }
`;

export const GET_ADMIN_BY_SUB_EMPLOYER_ID = gql`
  ${ADMIN_TO_EMPLOYER_PROFILE_FRAGMENT}
  query getAdminBySubEmployerId($employerProfileId: Int!) {
    getAdminBySubEmployerId(employerProfileId: $employerProfileId) {
      ...AdminToEmployerProfileFragment
    }
  }
`;
