import { makeVar } from '@apollo/client';

const createReactiveVarSetter = (reactiveVar) => (params) => {
  // similar as setter for useState
  if (typeof params === 'function') {
    const prev = reactiveVar();
    reactiveVar(params(prev));
  } else {
    reactiveVar(params);
  }
};

export const authVar = makeVar({ logout: () => {} });
export const employerSubscriptionVar = makeVar({});
export const allJobsArgsVar = makeVar({});

export const setAllJobsCommonVar = createReactiveVarSetter(allJobsArgsVar);
export const setEmployerSubscriptionVar = createReactiveVarSetter(employerSubscriptionVar);
